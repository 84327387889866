import Tippy from '@tippyjs/react';
import get from 'lodash/get';
import React from 'react';

import CustomButton from 'common/CustomButton';
import ProfileTemplate from 'components/profile/ProfileTemplate';
import Speciality from 'components/profile/qualification/speciality';
import StateLicenses from 'components/profile/qualification/StateLicenses';
import { QUALIFICATION } from 'constants/routes';
import { useLocalProfile } from 'hooks/useProfile';
import { useFetchQualifications } from 'requests/options';
import { useFetchProfileQualification, useUpdateQualification } from 'requests/profile';

import { useQualificationButtonDisabled } from '../../hooks/useQualificationButtonDisabled';
import { useDictionaryStore } from '../../stores/dictionaryStore';
import { useProfileQualificationsStore } from '../../stores/profile/qualificationStores';

export default function Qualification() {
  const qualificationsOptions = useDictionaryStore((s) => s.qualificationsOptions);
  const userQualifications = useProfileQualificationsStore((s) => s.userQualifications);
  const setUserQualifications = useProfileQualificationsStore((s) => s.setUserQualifications);
  const setQualificationIds = useProfileQualificationsStore((s) => s.setQualificationIds);
  const updateQualification = useUpdateQualification();
  const fetchQualifications = useFetchQualifications();
  const fetchProfileQualification = useFetchProfileQualification();

  const country = useLocalProfile((current) => get(current, 'country'));
  const qualificationIds = get(userQualifications, 'qualifications', []);
  const stateLicenses = get(userQualifications, 'state_licenses', []);

  const changeTitle = (qualification_title: string) =>
    setUserQualifications({ qualification_title });

  const changeLicenses = (state_licenses: string[]) => setUserQualifications({ state_licenses });

  React.useEffect(() => {
    fetchQualifications();
    fetchProfileQualification();
  }, []);

  const isLoading = React.useMemo(() => {
    return !qualificationsOptions.length || !userQualifications;
  }, [qualificationsOptions, userQualifications]);

  const { isDisabled, message } = useQualificationButtonDisabled(isLoading, userQualifications);

  return (
    <ProfileTemplate currentTab={QUALIFICATION}>
      <Speciality
        label="Your specialization"
        setQualificationIds={(q) => setQualificationIds(qualificationsOptions, q)}
        qualifications={qualificationsOptions}
        qualificationIds={qualificationIds}
        onTitleChange={changeTitle}
        title={userQualifications?.qualification_title ?? ''}
        isLoading={isLoading}
      />
      <StateLicenses
        isLoading={isLoading}
        country={country}
        onChangeLicense={changeLicenses}
        qualificationIds={qualificationIds}
        stateLicenses={stateLicenses}
      />
      <Tippy content={message} disabled={!isDisabled}>
        <span className="mt-14 w-32">
          <CustomButton
            className="w-32"
            clickHandler={updateQualification}
            isLoading={isLoading}
            disabled={isDisabled}
          >
            Update
          </CustomButton>
        </span>
      </Tippy>
    </ProfileTemplate>
  );
}
