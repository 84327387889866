import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.css';

interface ModalProps {
  children: React.ReactNode;
  background?: string;
  minWidth?: number;
  maxWidth?: number;
  title?: string;
  closeModal?: () => void;
  withSeparator?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  background = '#FFFFFF',
  minWidth,
  maxWidth,
  title = '',
  closeModal,
  withSeparator,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      closeModal?.();
    }
  };
  const handleBackdropKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      closeModal?.();
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        closeModal?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);

  return ReactDOM.createPortal(
    <div
      className={styles.wrapper}
      role="dialog"
      aria-modal="true"
      onClick={handleBackdropClick}
      onKeyDown={handleBackdropKeyDown}
    >
      <div
        ref={containerRef}
        className={styles.container}
        style={{
          background,
          minWidth: minWidth ? `${minWidth}px` : undefined,
          maxWidth: maxWidth ? `${maxWidth}px` : undefined,
        }}
      >
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <button
            className={styles.closeButton}
            onClick={() => closeModal?.()}
            aria-label="Close modal"
          >
            &times;
          </button>
        </div>
        {withSeparator && <hr className={styles.separator} />}
        {children}
      </div>
    </div>,
    document.getElementById('modal')!,
  );
};
