import React, { JSX } from 'react';

import { IconSvgProps } from 'components/new/Icon/Icon.types';

export const ThreeDots = ({ width = 36, height = 20, className }: IconSvgProps): JSX.Element => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 36 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="18" cy="2" rx="2" ry="2" transform="rotate(90 18 2)" fill="#223232" />
      <ellipse cx="18" cy="10" rx="2" ry="2" transform="rotate(90 18 10)" fill="#223232" />
      <ellipse cx="18" cy="18" rx="2" ry="2" transform="rotate(90 18 18)" fill="#223232" />
    </svg>
  );
};
