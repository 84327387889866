import get from 'lodash/get';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import CustomTextarea from 'common/CustomTextarea';
import { RegularMediumText, RegularBigText } from 'common/texts';
import { MultiFileUpload } from 'components/new/MultiFileUpload/MultiFileUpload';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { HEALTH_PLANS } from 'constants/routes';
import { usePatientHealthPlan, useSetPatientHealthPlan } from 'hooks/usePatients';
import { useCreatePatientHealthPlanV2 } from 'requests/patients';

export default function PatientHealthPlan() {
  const [isSent, setIsSent] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const healthPlan = usePatientHealthPlan((current) => get(current, 'healthPlan', ''));
  const setHealthPlan = useSetPatientHealthPlan(
    (prev, healthPlan) => ({ ...prev, healthPlan }),
    [],
  );

  const createPatientHealthPlan = useCreatePatientHealthPlanV2();

  const sendHandler = useCallback(() => {
    createPatientHealthPlan(setIsSent, selectedFiles);
  }, [createPatientHealthPlan, setIsSent, selectedFiles]);

  return (
    <DetailsTemplate currentTab={HEALTH_PLANS}>
      <RegularMediumText className="g-mb-10">Health Plan</RegularMediumText>

      <StyledCustomTextarea
        label=""
        placeholder="Write a comprehensive health plan for the patient"
        value={healthPlan}
        changeHandler={setHealthPlan}
      />

      <RegularBigText className="g-mt-20">Upload Files</RegularBigText>
      <UploadWrapper>
        <MultiFileUpload
          onFilesSelect={setSelectedFiles}
          label="Upload health plan documents"
          files={selectedFiles}
        />
      </UploadWrapper>

      <StyledCustomButton disabled={isSent} clickHandler={sendHandler}>
        Send
      </StyledCustomButton>
    </DetailsTemplate>
  );
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 30rem;
  }
  .loader-input {
    min-height: 30rem;
  }
`;

const StyledCustomButton = styled(CustomButton)`
  margin: 4.5rem auto 0 0;
`;

const UploadWrapper = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
