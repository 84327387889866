import React from 'react';

import CustomButton from 'common/CustomButton';
import Modal from 'common/Modal';

export default React.memo(function ConfirmationModal({
  disabled = false,
  closeModal,
  confirmHandler,
}) {
  return (
    <Modal title="Are you sure?" minWidth="30" maxWidth={30} closeModal={closeModal}>
      <CustomButton disabled={disabled} className="g-mt-20" clickHandler={confirmHandler}>
        Confirm
      </CustomButton>
    </Modal>
  );
});
