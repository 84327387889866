import get from 'lodash/get';
import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import CustomTextarea from 'common/CustomTextarea';
import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder';
import Spinner from 'common/loaders/Spinner';
import { RegularMediumText, RegularBigText } from 'common/texts';
import { FileItem } from 'components/new/FileItem/FileItem';
import { MultiFileUpload } from 'components/new/MultiFileUpload/MultiFileUpload';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { HEALTH_PLANS } from 'constants/routes';
import { usePatientHealthPlan, useSetPatientHealthPlan } from 'hooks/usePatients';
import { useEditPatientHealthPlanV2, useFetchPatientHealthPlanV2 } from 'requests/patients';
import { getFileNameFromUrl } from 'utils/patients/utils';

import styles from './PatientEditHealthPlan.module.css';

const PatientHealthPlan = React.memo(function PatientHealthPlan() {
  const setHealthPlan = useSetPatientHealthPlan((prev, text) => ({ ...prev, text }));
  const patientHealthPlan = usePatientHealthPlan();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showUploader, setShowUploader] = useState(false);
  const existingAttachments = get(patientHealthPlan, 'attachments', []);
  const editPatientHealthPlan = useEditPatientHealthPlanV2();

  const sendHandler = useCallback(() => {
    editPatientHealthPlan(selectedFiles);
  }, [editPatientHealthPlan, selectedFiles]);

  const isLoading = useMemo(() => !patientHealthPlan, [patientHealthPlan]);
  if (isLoading) {
    return <ListPreviewPlaceholder />;
  }

  const text = get(patientHealthPlan, 'text', '');
  const renderExistingAttachments = () => {
    if (!showUploader && existingAttachments.length > 0) {
      return (
        <div>
          <RegularBigText className="g-mt-20">Existing files</RegularBigText>
          <div className={styles.existingAttachments}>
            <div>
              {existingAttachments.map((item) => {
                const fileName = getFileNameFromUrl(item.url);
                return (
                  <FileItem
                    key={item.id}
                    name={fileName || `Health Plan Attachment ${item.id}`}
                    linkUrl={item.url}
                    showRemoveIcon={false}
                  />
                );
              })}
            </div>
            <div>
              <TransparentButton clickHandler={() => setShowUploader(true)}>
                Replace files
              </TransparentButton>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderUploader = () => {
    if (showUploader || existingAttachments.length === 0) {
      return (
        <>
          <RegularBigText className="g-mt-20">Upload files</RegularBigText>
          <UploadWrapper>
            <MultiFileUpload onFilesSelect={setSelectedFiles} label="Upload health plans" />
          </UploadWrapper>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <StyledCustomTextarea
        label=""
        placeholder="Write a comprehensive health plan for the patient"
        value={text}
        changeHandler={setHealthPlan}
      />
      {renderExistingAttachments()}
      {renderUploader()}

      <StyledCustomButton clickHandler={sendHandler}>Send</StyledCustomButton>
    </>
  );
});

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 30rem;
  }

  .loader-input {
    min-height: 30rem;
  }
`;

const StyledCustomButton = styled(CustomButton)`
  margin: 4.5rem auto 0 0;
`;

const TransparentButton = styled(CustomButton)`
  background-color: transparent;
  border: 2px solid #16123f;
  color: #16123f;
  padding-left: 14px;
  padding-right: 14px;

  &:hover {
    box-shadow: 0 0 2px #333333;
  }
`;

const UploadWrapper = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default function EditHealthPlan() {
  const { healthPlanID } = useParams();
  const { loading, fetchHealthPlan } = useFetchPatientHealthPlanV2(healthPlanID);

  React.useEffect(() => {
    fetchHealthPlan();
  }, [fetchHealthPlan]);

  return (
    <DetailsTemplate currentTab={HEALTH_PLANS}>
      <RegularMediumText className="g-mb-10">Edit health plan</RegularMediumText>
      {loading ? <Spinner /> : <PatientHealthPlan />}
    </DetailsTemplate>
  );
}
