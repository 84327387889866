import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import React from 'react';
import { default as ReactDropdownSelect } from 'react-dropdown-select';

import { UppercaseText } from 'common/texts';
import { ErrorMessage } from 'components/new/ErrorMessage';

import { InputLoader } from '../InputLoader';
import { DropdownRenderer, DropdownRendererProps } from './DropdownRenderer';
import styles from './Select.module.css';
import type { BaseSelectProps, SelectProps } from './Select.types';

export const Select: React.FC<SelectProps> = ({
  label,
  required,
  className,
  isLoading,
  containerClassName = '',
  isValid = true,
  error,
  tooltipTextWhenDisabled = '',
  isSearchable = false,
  disabled,
  placeholder = 'Select',
  ...restProps
}) => {
  if (isLoading) {
    return <InputLoader />;
  }

  const shouldTooltipBeDisabled = !tooltipTextWhenDisabled || !disabled;

  const contentRenderer: BaseSelectProps['contentRenderer'] = ({ props, state }) => {
    const content =
      (state.values[0]?.[props.labelField as keyof (typeof state.values)[number]] || placeholder) ??
      'Select';
    return (
      <div className={styles.selectField} title={content}>
        {content}
      </div>
    );
  };

  const renderDropdown = (props: DropdownRendererProps, isSearchable: boolean) => {
    return <DropdownRenderer {...props} isSearchable={isSearchable} />;
  };
  const dropdownRenderer = disabled ? undefined : (props) => renderDropdown(props, isSearchable);

  return (
    <div
      className={classnames(styles.selectContainer, containerClassName, {
        [styles.noLabel]: !label,
      })}
    >
      {label && (
        <label
          htmlFor={label}
          className={classnames(styles.label, { [styles.labelError]: !isValid })}
        >
          <UppercaseText>
            {label}
            {required && <span className={styles.required}>*</span>}
          </UppercaseText>
        </label>
      )}
      <Tippy
        content={tooltipTextWhenDisabled}
        placement="top"
        interactive={true}
        disabled={shouldTooltipBeDisabled}
      >
        <div className={styles.selectWrapper}>
          <ReactDropdownSelect
            {...restProps}
            className={classnames(
              styles.select,
              { [styles.notValid]: !isValid && !disabled, [styles.disabled]: disabled },
              className,
            )}
            contentRenderer={contentRenderer}
            dropdownRenderer={dropdownRenderer}
            disabled={disabled}
            separator={true}
          />
        </div>
      </Tippy>

      {error && <ErrorMessage message={error} />}
    </div>
  );
};
