import React from 'react';

import Spinner from 'common/loaders/Spinner';
import { usePatientHealthPlans } from 'hooks/usePatients';

import PlansList from './PlansList';

export default React.memo(function HealthPlans() {
  const patientHealthPlans = usePatientHealthPlans();

  const isLoading = React.useMemo(() => !patientHealthPlans, [patientHealthPlans]);

  if (isLoading) {
    return <Spinner />;
  }

  return <PlansList />;
});
