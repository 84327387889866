import classnames from 'classnames';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { UppercaseText } from 'common/texts';
import {
  FileExtension,
  FileUploadAreaProps,
  FileUploadAreaRef,
} from 'components/new/FileUploadArea/FileUploadArea.types';
import { Icon } from 'components/new/Icon/Icon';
import { validateFile } from 'utils/fileValidation';

import { ErrorMessage } from '../ErrorMessage';
import { InputLoader } from '../InputLoader';
import styles from './FileUploadArea.module.css';

const DEFAULT_SIZE_IN_MB = 5;

export const FileUploadArea = forwardRef<FileUploadAreaRef, FileUploadAreaProps>(function (
  {
    label,
    isLoading,
    required,
    containerClassName,
    onChange,
    acceptedFormats = ['jpg', 'jpeg', 'png', 'pdf'],
    value = '',
    clearValue,
    isValid = true,
    cancelUpload,
    selectedFilename,
    disabled,
    error,
    maxSizeInMB = DEFAULT_SIZE_IN_MB,
  },
  ref,
) {
  const [acceptedExtensions] = useState<FileExtension[]>(acceptedFormats);
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileWrapperRef = useRef<HTMLDivElement>(null);
  const allowedFormats = useMemo(() => `.${acceptedExtensions.join(', .')}`, [acceptedExtensions]);
  const [localError, setLocalError] = useState('');
  const clearFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useImperativeHandle(ref, () => {
    return {
      clearFile,
    };
  }, []);

  const manageDropSignal = (action: 'add' | 'remove') => {
    if (file || disabled) {
      return;
    }
    if (action === 'add') {
      fileWrapperRef.current?.classList.add('drop-signal');
    } else if (action === 'remove') {
      fileWrapperRef.current?.classList.remove('drop-signal');
    }
  };
  if (isLoading) {
    return <InputLoader inputHeight={123} />;
  }

  const handleFileSelect = async (files: FileList | null) => {
    setLocalError('');
    if (file || disabled) {
      return;
    }
    if (!files?.length) {
      setLocalError('You did not select a file');
      return;
    }
    const selectedFile = files[0];
    const { valid, error } = await validateFile(selectedFile, acceptedFormats, maxSizeInMB);

    if (!valid) {
      setLocalError(error ?? 'Invalid file');
      return;
    }

    setFile(selectedFile);
    if (onChange) {
      onChange(selectedFile);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const isDocumentUploaded = value?.startsWith('http');

  const renderSelectedFile = () => {
    if (!file && !value) {
      return;
    }

    const fileUrl = isDocumentUploaded ? value : undefined;
    const displayText = file?.name ?? selectedFilename ?? `${value.slice(0, 20)}...`;

    const icon = <Icon name="document" height={18} width={18} />;
    const text = <span>{displayText}</span>;
    return fileUrl ? (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer" className={styles.selected}>
        {icon}
        {text}
      </a>
    ) : (
      <div className={styles.selected}>
        {icon}
        {text}
      </div>
    );
  };

  const renderUploadButton = () => {
    if (file || value) {
      return;
    }
    return (
      <button
        className={styles.uploadBtn}
        onClick={(e) => {
          e.stopPropagation();
          fileInputRef.current?.click();
        }}
      >
        <Icon name="upload" height={18} width={18} />
        <span>Upload</span>
      </button>
    );
  };

  const handleClearButton = () => {
    setLocalError('');
    clearFile();
    if (clearValue) {
      clearValue();
    }
    if (cancelUpload && !file && !value) {
      cancelUpload();
    }
  };

  const renderClearButton = () => {
    if (!file && !value && !cancelUpload) {
      return;
    }
    return (
      <button
        className={styles.clearBtn}
        onClick={(e) => {
          e.stopPropagation();
          handleClearButton();
        }}
      >
        <Icon name="close" height={14} width={14} />
      </button>
    );
  };

  const renderDescription = () => {
    return (
      <p className={styles.description}>
        {file || value ? (
          <>{isDocumentUploaded && <span>Uploaded</span>}</>
        ) : (
          <span>
            Recommended size 100x100
            <br />
            Formats allowed: {allowedFormats}
          </span>
        )}
      </p>
    );
  };

  return (
    <div className={classnames(styles.fileUploadContainer, containerClassName)}>
      {label && (
        <UppercaseText className="g-mb-10">
          {label}
          {required && <span className={styles.required}>*</span>}
        </UppercaseText>
      )}
      <div
        ref={fileWrapperRef}
        role="button"
        tabIndex={0}
        className={classnames(styles.fileUpload, {
          [styles.notValid]: !isValid,
          [styles.disabled]: disabled,
        })}
        onDragOver={(e) => {
          e.preventDefault();
          manageDropSignal('add');
        }}
        onDragLeave={() => manageDropSignal('remove')}
        onDragEnd={() => manageDropSignal('remove')}
        onDrop={async (e) => {
          e.preventDefault();
          manageDropSignal('remove');
          await handleFileSelect(e.dataTransfer.files);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.stopPropagation();
            !disabled && fileInputRef.current?.click();
          }
        }}
      >
        {renderClearButton()}
        {renderSelectedFile()}
        {renderUploadButton()}
        {renderDescription()}
      </div>
      <input
        type="file"
        className="sr-only"
        disabled={!!file || disabled}
        onChange={async (e) => {
          if (file || disabled) {
            e.preventDefault();
          }
          await handleFileSelect(e.target.files);
        }}
        accept={allowedFormats}
        ref={fileInputRef}
      />
      {(localError || error) && <ErrorMessage message={localError ?? error!} />}
    </div>
  );
});

FileUploadArea.displayName = 'FileUploadArea';
