import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import LinkButton from 'common/LinkButton';
import Modal from 'common/Modal';
import { MediumText, UppercaseText, RegularText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';
import { PROVIDERS, SUMMARY } from 'constants/routes';
import { globalContext } from 'hooks/useGlobalState';
import { useFetchProviders } from 'requests/providers';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export default React.memo(function ProviderPreview({
  id,
  avatar,
  firstName,
  lastName,
  fullName,
  qualificationTitle,
  lastActivity,
}) {
  const { providersListFilters } = React.useContext(globalContext);
  const fetchProviders = useFetchProviders();
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  const parsedLastActivity = React.useMemo(() => {
    if (!lastActivity) {
      return 'Have not login yet';
    }
    return moment(lastActivity).fromNow();
  }, [lastActivity]);

  const navigateToDetails = React.useCallback(() => {
    history.push(`/${PROVIDERS}/${id}/${SUMMARY}`);
  }, [history, id]);

  const handleDeleteProvider = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/admins/providers/${id}`,
    })
      .then(() => {
        createNotification({ message: 'Provider successfully deleted', type: 'success' });
        fetchProviders(providersListFilters.value);
        closeModal();
      })
      .catch((error) => console.info(error));
  }, [id, fetchProviders, providersListFilters, closeModal]);

  return (
    <StyledWrapper>
      <UserAvatar className="avatar" avatar={avatar} firstName={firstName} lastName={lastName} />

      <div className="information">
        <MediumText className="name">{fullName}</MediumText>
        <UppercaseText>{qualificationTitle}</UppercaseText>
      </div>

      <div className="activity">
        <RegularText>Last activity</RegularText>
        <UppercaseText>{parsedLastActivity}</UppercaseText>
      </div>

      <div className="buttons-wrapper">
        <LinkButton className="view-button g-mr-10" onClick={navigateToDetails}>
          View
        </LinkButton>

        <LinkButton className="delete-button" onClick={openModal}>
          Delete
        </LinkButton>
      </div>

      {isOpen && (
        <Modal minWidth={'29'} maxWidth={'29'} title="Are you sure?" closeModal={closeModal}>
          <CustomButton className="g-mt-20" clickHandler={handleDeleteProvider}>
            Remove provider
          </CustomButton>
        </Modal>
      )}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  padding: 2rem;
  background: #fafbfd;
  border: solid 1px #e9ebef;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  .avatar {
    width: 5.3rem;
    height: 5.3rem;
    margin-right: 1.5rem;
  }
  .name {
    line-height: 2.5rem;
  }
  .information {
    width: 30%;
  }
  .buttons-wrapper {
    display: flex;
    margin-left: auto;
    .view-button {
      width: 11rem;
    }
    .delete-button {
      width: 11rem;
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    .information {
      width: initial;
      text-align: center;
    }
    .activity {
      text-align: center;
    }
    .buttons-wrapper {
      width: 100%;
      margin-top: 1rem;
      .view-button {
        width: 50%;
      }
      .delete-button {
        width: 50%;
      }
    }
  }
`;
