import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from 'App';
import { GOOGLE_CALENDAR_CLIENT_ID, SENTRY } from 'env';

import 'tippy.js/dist/tippy.css';
import './index.css';
import './datepicker.css';

const isSentry = SENTRY;

if (isSentry === 'production') {
  Sentry.init({
    dsn: 'https://fb8142b1c8bc8f17c5a619255fa16339@o4504128864845824.ingest.us.sentry.io/4507062047277056',
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root container missing in index.html');
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GOOGLE_CALENDAR_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
);

if (module.hot) {
  module.hot.accept('App', () => {
    const NextApp = require('App').default;
    root.render(
      <React.StrictMode>
        <GoogleOAuthProvider clientId={GOOGLE_CALENDAR_CLIENT_ID}>
          <NextApp />
        </GoogleOAuthProvider>
      </React.StrictMode>,
    );
  });
}
