import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import React from 'react';

import CustomButton from 'common/CustomButton';
import CustomCheckbox from 'common/CustomCheckbox';
import { FlexLine } from 'common/plate/styles';
import { MediumText, RegularText } from 'common/texts';
import { FileUploadArea } from 'components/new/FileUploadArea';
import { Input } from 'components/new/Input';
import { Select } from 'components/new/Select';
import {
  useLocalPatientInsurance,
  usePatientInsurance,
  useSetLocalPatientInsurance,
} from 'hooks/usePatients';
import { useInsuranceCompanies, useSetInsuranceCompanies } from 'hooks/useSettings';
import { useFetchInsuranceCompanies, useUpdatePatientInsurance } from 'requests/patients/patients';
import createNotification from 'utils/createNotification';
import { getBase64 } from 'utils/getBase64';

import styles from './Insurance.module.css';

export default function Insurance() {
  const patientInsurance = usePatientInsurance();
  const localPatientInsurance = useLocalPatientInsurance();

  const updatePatientInsurance = useUpdatePatientInsurance();
  /** @type {Array<{ label: string; value: string }> | null} */
  const insuranceCompanies = useInsuranceCompanies();
  const clearInsuranceCompanies = useSetInsuranceCompanies(() => null, []);
  const fetchInsuranceCompanies = useFetchInsuranceCompanies();

  React.useEffect(() => {
    fetchInsuranceCompanies();
    return () => {
      clearInsuranceCompanies();
    };
  }, []);

  const withoutInsurance = get(localPatientInsurance, 'no_insurance', false) || false;

  /**
   * @param {InsuranceType} field - primary insurance key.
   */
  const getLocalInsuranceValue = (field) => {
    const value = get(localPatientInsurance, `insurances.primary.${field}`, '');
    if (withoutInsurance || !value) {
      return '';
    }
    return value;
  };

  const company = getLocalInsuranceValue('company');
  const group = getLocalInsuranceValue('group');
  const member = getLocalInsuranceValue('member');
  const guarantor = getLocalInsuranceValue('guarantor');
  const poBox = getLocalInsuranceValue('po_box');
  const insuranceCardFront = getLocalInsuranceValue('insurance_card_front');
  const insuranceCardBack = getLocalInsuranceValue('insurance_card_back');
  const userInsuranceCardFront =
    get(localPatientInsurance, 'insurances.primary.insurance_card_front', '') || '';
  const userInsuranceCardBack =
    get(localPatientInsurance, 'insurances.primary.insurance_card_back', '') || '';

  const setCompany = useSetLocalPatientInsurance((prev, company) => {
    const newPrev = cloneDeep(prev);
    set(newPrev, 'insurances.primary.company', company);
    return newPrev;
  });

  const setCardFront = useSetLocalPatientInsurance((prev, url) => {
    const newPrev = cloneDeep(prev);
    set(newPrev, 'insurances.primary.insurance_card_front', url);
    return newPrev;
  });

  const setCardBack = useSetLocalPatientInsurance((prev, url) => {
    const newPrev = cloneDeep(prev);
    set(newPrev, 'insurances.primary.insurance_card_back', url);
    return newPrev;
  });

  const alphanumericDashesRegex = /^[\w-]+$/;
  const alphanumericSpacesRegex = /^[\w ]+$/;
  const alphanumericDashesPunctuationRegex = /^[\w-., ]+$/;

  const isCompanyValid = !!company;
  const isGroupValid = alphanumericDashesRegex.test(group);
  const isMemberValid = alphanumericDashesRegex.test(member);
  const isGuarantorValid = alphanumericSpacesRegex.test(guarantor.trim());
  const isPoBoxValid = alphanumericDashesPunctuationRegex.test(poBox);
  const isCardFrontValid = !!insuranceCardFront;
  const isCardBackValid = !!insuranceCardBack;

  const updateCondition = React.useMemo(() => {
    if (!withoutInsurance) {
      const isInputsValid =
        isCompanyValid &&
        isGroupValid &&
        isMemberValid &&
        isGuarantorValid &&
        isPoBoxValid &&
        isCardFrontValid &&
        isCardBackValid;
      if (!patientInsurance || !localPatientInsurance || !isInputsValid) {
        return false;
      }
    }
    return !isEqual(patientInsurance, localPatientInsurance);
  }, [patientInsurance, localPatientInsurance]);

  const companyList = React.useMemo(() => {
    if (insuranceCompanies) {
      return insuranceCompanies.map(({ label }) => ({ label, value: label }));
    }
    return [];
  }, [insuranceCompanies]);

  const currentCompany = React.useMemo(() => {
    return companyList.find((item) => item.label === company) ?? [];
  }, [companyList, company, withoutInsurance]);

  const handleFileChange = async (file, type) => {
    try {
      const base64String = await getBase64(file);
      if (type === 'front') {
        setCardFront(base64String);
      } else if (type === 'back') {
        setCardBack(base64String);
      }
    } catch (e) {
      createNotification({ message: 'Cannot select file', type: 'error' });
      console.error(e);
    }
  };

  /** @returns {InsuranceError} */
  const getErrors = () => {
    /** @type {InsuranceError} */
    const errors = {};
    if (withoutInsurance) {
      return errors;
    }
    if (!company) {
      errors.company = 'Select a company';
    }
    if (!group) {
      errors.group = 'Provide a valid group';
    } else if (!isGroupValid) {
      errors.group = 'The Group number must only contain alphanumeric characters and dashes.';
    }
    if (!member) {
      errors.member = 'Provide a valid member/plan ID number';
    } else if (!isMemberValid) {
      errors.member = 'The Member/Plan ID must only contain alphanumeric characters and dashes.';
    }
    if (!guarantor) {
      errors.guarantor = 'Provide a valid guarantor';
    } else if (!isGuarantorValid) {
      errors.guarantor = 'The Guarantor must only contain alphanumeric characters and spaces.';
    }
    if (!poBox) {
      errors.po_box = 'Provide a valid PO box';
    } else if (!isPoBoxValid) {
      errors.po_box =
        'The PO box must only contain alphanumeric characters, dashes, dots, and commas.';
    }
    if (!isCardFrontValid) {
      errors.insurance_card_front = 'Provide the front view of your insurance card';
    }
    if (!isCardBackValid) {
      errors.insurance_card_back = 'Provide the back view of your insurance card';
    }
    return errors;
  };

  /** @type {React.RefObject<FileUploadRef>} */
  const insuranceCardFrontRef = React.useRef(null);
  /** @type {React.RefObject<FileUploadRef>} */
  const insuranceCardBackRef = React.useRef(null);
  const setWithoutInsurance = useSetLocalPatientInsurance((prev, checked) => ({
    ...prev,
    no_insurance: checked,
  }));

  return (
    <>
      <CustomCheckbox
        label="Patient doesn't have insurance"
        checked={withoutInsurance}
        changeHandler={(event) => {
          const checked = event.target.checked;
          setWithoutInsurance(checked);
          if (checked) {
            insuranceCardFrontRef.current?.clearFile();
            insuranceCardBackRef.current?.clearFile();
          }
        }}
      />
      <MediumText className="g-mt-15">Update the insurance information</MediumText>

      <RegularText className="g-mt-5">
        Current insurance information allows us to properly process lab work or prescriptions
        ordered by the provider.
      </RegularText>

      <MediumText className="g-mt-20">Primary insurance</MediumText>
      <div>
        <FlexLine className={styles.insuranceFlexLine}>
          <Select
            containerClassName={styles.insuranceInputGeneric}
            label="INSURANCE PROVIDER NAME"
            values={[currentCompany]}
            options={companyList}
            disabled={withoutInsurance}
            required
            isLoading={!insuranceCompanies || !patientInsurance}
            isValid={isCompanyValid}
            onChange={([option]) => !withoutInsurance && setCompany(option?.label)}
            error={getErrors().company}
            isSearchable
          />
          <Input
            className={styles.insuranceInputGeneric}
            label="GUARANTOR FIRST AND LAST NAME"
            value={guarantor}
            changeHandler={useSetLocalPatientInsurance((prev, member) => {
              const newPrev = cloneDeep(prev);
              set(newPrev, 'insurances.primary.guarantor', member);
              return newPrev;
            })}
            disabled={withoutInsurance}
            isRequired
            isLoading={!patientInsurance}
            isValid={isGuarantorValid}
            error={getErrors().guarantor}
          />
          <Input
            className={styles.insuranceInputGeneric}
            label="MEMBER/PLAN ID"
            value={member}
            changeHandler={useSetLocalPatientInsurance((prev, value) => {
              const newPrev = cloneDeep(prev);
              set(newPrev, 'insurances.primary.member', value);
              return newPrev;
            })}
            disabled={withoutInsurance}
            isRequired
            isLoading={!patientInsurance}
            isValid={isMemberValid}
            error={getErrors().member}
          />
        </FlexLine>
        <FlexLine className={styles.insuranceFlexLine}>
          <Input
            className={styles.insuranceInputGeneric}
            label="GROUP NUMBER"
            value={group}
            changeHandler={useSetLocalPatientInsurance((prev, company) => {
              const newPrev = cloneDeep(prev);
              set(newPrev, 'insurances.primary.group', company);
              return newPrev;
            })}
            disabled={withoutInsurance}
            isRequired
            isLoading={!patientInsurance}
            isValid={isGroupValid}
            error={getErrors().group}
          />

          <Input
            className={styles.insuranceInputGeneric}
            label="CLAIMS PO BOX"
            value={poBox}
            changeHandler={useSetLocalPatientInsurance((prev, group) => {
              const newPrev = cloneDeep(prev);
              set(newPrev, 'insurances.primary.po_box', group);
              return newPrev;
            })}
            disabled={withoutInsurance}
            isRequired
            isLoading={!patientInsurance}
            isValid={isPoBoxValid}
            error={getErrors().po_box}
          />
        </FlexLine>

        <FlexLine className={styles.insuranceFlexLine}>
          <FileUploadArea
            ref={insuranceCardFrontRef}
            label="Upload Insurance Card – Front"
            containerClassName={styles.insuranceInputGeneric}
            onChange={(file) => void handleFileChange(file, 'front')}
            value={insuranceCardFront}
            clearValue={() => setCardFront('')}
            isLoading={!patientInsurance}
            cancelUpload={userInsuranceCardFront && (() => setCardFront(userInsuranceCardFront))}
            selectedFilename="FRONT"
            required
            isValid={isCardFrontValid}
            disabled={withoutInsurance}
            error={getErrors().insurance_card_front}
          />
          <FileUploadArea
            ref={insuranceCardBackRef}
            label="Upload Insurance Card – Back"
            containerClassName={styles.insuranceInputGeneric}
            onChange={(file) => void handleFileChange(file, 'back')}
            value={insuranceCardBack}
            clearValue={() => setCardBack('')}
            isLoading={!patientInsurance}
            cancelUpload={userInsuranceCardBack && (() => setCardBack(userInsuranceCardBack))}
            selectedFilename="BACK"
            required
            isValid={isCardBackValid}
            disabled={withoutInsurance}
            error={getErrors().insurance_card_back}
          />
        </FlexLine>
      </div>

      <RegularText className="g-mt-20 g-mb-30">
        A note about insurance: The patient can use the patient’s insurance for any prescriptions or
        lab tests ordered by Ciba Health Inc, just as the patient would at another primary care
        doctor’s office. We don’t take insurance for our membership fee, but if the patient has an
        FSA, HSA, or out-of-network benefits, it could cover the patient’s care at Ciba Health Inc.
      </RegularText>

      <CustomButton
        className="g-mt-auto"
        disabled={!updateCondition}
        clickHandler={updatePatientInsurance}
      >
        Update
      </CustomButton>
    </>
  );
}
