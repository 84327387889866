/**
 * Creates a window reference that can be used for asynchronous URL opening.
 * This is especially important for iOS Safari which blocks window.open() calls
 * inside asynchronous callbacks.
 *
 * Usage:
 * ```
 * const windowRef = createWindowForAsync();
 * fetchUrl().then(url => {
 *   if (windowRef) windowRef.location = url;
 * });
 * ```
 *
 * @returns Window reference or null if blocked
 */
export const createWindowForAsync = (): Window | null => {
  try {
    return window.open();
  } catch (error) {
    console.warn('Failed to create window reference', error);
    return null;
  }
};

/**
 * Opens a URL asynchronously in a way that's compatible with iOS Safari.
 * This function creates a window reference first, then sets its location
 * when the promise resolves.
 *
 * @param urlPromise - Promise that resolves to the URL to open
 * @returns Promise that resolves when the URL is opened
 */
export const openUrlAsync = (urlPromise: Promise<string>): Promise<void> => {
  // Create window reference before async operation
  const windowRef = createWindowForAsync();

  return urlPromise
    .then((url) => {
      if (windowRef) {
        windowRef.location = url;
      } else {
        console.warn('Pop-up was blocked. Please allow pop-ups for this site.');
      }
    })
    .catch((error) => {
      console.error('Failed to open URL asynchronously', error);
      if (windowRef) {
        windowRef.close();
      }
    });
};
