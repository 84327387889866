import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { PATIENTS, DETAILS, DOCTOR_NOTES, HEALTH_PLANS, ADMIN_NOTES } from 'constants/routes';
import { globalContext } from 'hooks/useGlobalState';
import {
  usePatinetsFilter,
  useSetPatientFullName,
  useSetPatinets,
  useSetPatinetsPagination,
  useSetPatientOverview,
  useSetPatientTestState,
  useSetPatientProfile,
  useLocalPatientProfile,
  useSetLocalPatientProfile,
  useSetPatinetDoctorNotes,
  useSetPatientSymptomsList,
  useSetPatientHealthPlans,
  useSetPatinetDoctorNote,
  useSetPatientDocuments,
  useSetPatientLabs,
  useSetPatientAdminNotes,
  useSetPatientAdminNote,
  useSetPatientInsurance,
  useSetLocalPatientInsurance,
} from 'hooks/usePatients';
import { useSetInsuranceCompanies } from 'hooks/useSettings';
import createNotification from 'utils/createNotification';
import normalizeParams from 'utils/normalizeParams';
import request from 'utils/request';

export function useFetchPatinets() {
  const { status } = useParams();
  const { search } = useLocation();
  const patinetsFilter = usePatinetsFilter();
  const setPatinets = useSetPatinets((prev, next) => next, []);
  const setPatinetsPagination = useSetPatinetsPagination((prev, next) => next, []);
  return React.useCallback(() => {
    const query = new URLSearchParams(search);
    const page = query.get('page') || 1;
    const sort_by = query.get('sort_by');
    const sort_dir = query.get('sort_dir');
    request({
      method: 'get',
      url: '/patients',
      params: normalizeParams({
        ...patinetsFilter,
        status,
        page,
        sort_by,
        sort_dir,
      }),
    })
      .then(({ data: { data } }) => {
        setPatinets(data.patients);
        setPatinetsPagination(data.meta);
      })
      .catch((error) => console.info(error));
  }, [search, patinetsFilter, status, setPatinets, setPatinetsPagination]);
}

export function useFetchPatientOverview() {
  const { patientId } = useParams();
  const setPatientOverview = useSetPatientOverview((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/patients/${patientId}/overview`,
    })
      .then(({ data: { data } }) => {
        setPatientOverview(data);
      })
      .catch((error) => console.info(error));
  }, [patientId, setPatientOverview]);
}

export function useFetchPatinetProfile() {
  const { patientId } = useParams();
  const setPatinetProfile = useSetPatientProfile((prev, next) => next, []);
  const setLocalPatientProfile = useSetLocalPatientProfile((prev, next) => next, []);
  const setPatientFullName = useSetPatientFullName((prev, next) => next, []);
  const setTestPatientState = useSetPatientTestState((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/patients/${patientId}/profile`,
    })
      .then(({ data: { data } }) => {
        data.patient.emergency = null === data.patient.emergency ? [] : data.patient.emergency;
        setPatinetProfile(data.patient);
        setLocalPatientProfile(data.patient);
        setTestPatientState(!!data.patient.is_test);
        if (data.patient.full_name) {
          setPatientFullName(data.patient.full_name);
        }
      })
      .catch((error) => console.info(error));
  }, [patientId, setPatinetProfile, setLocalPatientProfile, setPatientFullName]);
}

export function useUpdatePatientProfile() {
  const [isLoading, setIsLoading] = React.useState(false); // Add isLoading state
  const { patientId } = useParams();
  const localPatientProfile = useLocalPatientProfile();
  const setPatinetProfile = useSetPatientProfile((prev, next) => next, []);
  const setLocalPatientProfile = useSetLocalPatientProfile((prev, next) => next, []);

  const updateProfile = React.useCallback(() => {
    setIsLoading(true);
    request({
      method: 'post',
      url: `/admins/patients/${patientId}`,
      data: {
        ...localPatientProfile,
        phone_verified: true,
      },
    })
      .then(({ data }) => {
        createNotification({ message: 'Profile updated', type: 'success' });
        data.patient.emergency = null === data.patient.emergency ? [] : data.patient.emergency;
        setPatinetProfile(data);
        setLocalPatientProfile(data);
      })
      .catch((error) => console.info(error))
      .finally(() => setIsLoading(false));
  }, [setPatinetProfile, setLocalPatientProfile, localPatientProfile, patientId]);

  return { isLoading, updateProfile }; // Export isLoading state and updateProfile function
}

export function useFetchPatinetDoctorNotes() {
  const { patientId } = useParams();
  const setPatinetDoctorNotes = useSetPatinetDoctorNotes((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/doctor-notes',
      params: {
        patient: patientId,
      },
    })
      .then(({ data: { data } }) => {
        setPatinetDoctorNotes(data.doctorNotes);
      })
      .catch((error) => console.info(error));
  }, [patientId, setPatinetDoctorNotes]);
}

export function useFetchSymptomsList() {
  const setPatientSymptomsList = useSetPatientSymptomsList((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/options/systemsWithDiseases',
    })
      .then(({ data }) => {
        setPatientSymptomsList(data);
      })
      .catch((error) => console.info(error));
  }, [setPatientSymptomsList]);
}

export function useCreatePatinetDoctorNote() {
  const { patientId } = useParams();
  const history = useHistory();
  const { user, patientDoctorNote } = React.useContext(globalContext);
  const clearPatinetDoctorNote = useSetPatinetDoctorNote(() => null);
  return React.useCallback(() => {
    const providerId = get(user, 'value.id');
    const illnessHistory = get(patientDoctorNote, 'value.illnessHistory');
    const general = get(patientDoctorNote, 'value.general');
    const symptoms = get(patientDoctorNote, 'value.symptoms', []);
    const diseases = symptoms.map(({ value, text }) => ({ id: value, free_text: text }));
    const assessment = get(patientDoctorNote, 'value.assessment');
    request({
      method: 'post',
      url: `/providers/${providerId}/doctor-notes`,
      data: {
        patient: patientId,
        history: illnessHistory,
        systems_review_general: general,
        diseases,
        assessment,
      },
    })
      .then(() => {
        createNotification({ message: 'Doctor note created', type: 'success' });
        clearPatinetDoctorNote(null);
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}`);
      })
      .catch((error) => console.info(error));
  }, [user, patientId, patientDoctorNote, clearPatinetDoctorNote, history]);
}

export function useCreatePatinetDoctorNoteDraft() {
  const { patientId } = useParams();
  const { user, patientDoctorNote } = React.useContext(globalContext);
  return React.useCallback(() => {
    const providerId = get(user, 'value.id');
    const illnessHistory = get(patientDoctorNote, 'value.illnessHistory');
    const general = get(patientDoctorNote, 'value.general');
    const symptoms = get(patientDoctorNote, 'value.symptoms', []);
    const diseases = symptoms.map(({ value, text }) => ({ id: value, free_text: text }));
    const assessment = get(patientDoctorNote, 'value.assessment');
    if (!illnessHistory && !general && symptoms.length === 0 && !assessment) {
      return;
    }
    request({
      method: 'post',
      url: `/providers/${providerId}/doctor-notes`,
      data: {
        patient: patientId,
        history: illnessHistory,
        systems_review_general: general,
        diseases,
        assessment,
        draft: true,
        draft_page: get(patientDoctorNote, 'value.draftPage', '1'),
      },
    }).catch((error) => console.info(error));
  }, [user, patientId, patientDoctorNote]);
}

export function useFetchDoctorNote() {
  const { noteId } = useParams();
  const setPatinetDoctorNote = useSetPatinetDoctorNote((prev, next) => ({ ...prev, ...next }), []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/doctor-notes/${noteId}`,
    })
      .then(
        ({
          data: {
            data: { doctorNotes },
          },
        }) => {
          const diseases = get(doctorNotes, 'diseases', []);
          const symptoms = diseases.map((disease) => {
            return {
              value: disease.id.toString(),
              text: disease.free_text || '',
              label: disease.label || '',
              has_free_text: disease.has_free_text,
            };
          });
          const doctorNote = {
            medicalRecord: get(doctorNotes, 'medical_record'),
            illnessHistory: get(doctorNotes, 'history') || '',
            general: get(doctorNotes, 'systems_review_general') || '',
            symptoms,
            assessment: get(doctorNotes, 'assessment') || '',
            draft: get(doctorNotes, 'draft'),
            draft_page: get(doctorNotes, 'draftPage', '1'),
          };
          setPatinetDoctorNote(doctorNote);
        },
      )
      .catch((error) => console.info(error));
  }, [noteId, setPatinetDoctorNote]);
}

export function useUpdateDoctorNote() {
  const { patientId, noteId } = useParams();
  const history = useHistory();
  const { user, patientDoctorNote } = React.useContext(globalContext);
  return React.useCallback(() => {
    const providerId = get(user, 'value.id');
    const symptoms = get(patientDoctorNote, 'value.symptoms', []);
    const data = {
      id: noteId,
      patient: patientId,
      history: get(patientDoctorNote, 'value.illnessHistory'),
      systems_review_general: get(patientDoctorNote, 'value.general'),
      record_number: get(patientDoctorNote, 'value.medicalRecord'),
      assessment: get(patientDoctorNote, 'value.assessment'),
      diseases: symptoms.map(({ value, text }) => ({ id: value, free_text: text })),
    };
    request({
      method: 'put',
      url: `/providers/${providerId}/doctor-notes/${noteId}`,
      data,
    })
      .then(() => {
        createNotification({ message: 'Doctor note updated', type: 'success' });
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}`);
      })
      .catch((error) => console.info(error));
  }, [patientId, noteId, user, patientDoctorNote, history]);
}

export function useUpdateDoctorNoteDraft() {
  const { patientId, noteId } = useParams();
  const { user, patientDoctorNote } = React.useContext(globalContext);
  return React.useCallback(() => {
    if (!get(patientDoctorNote, 'value.draft')) {
      return;
    }
    const providerId = get(user, 'value.id');
    const symptoms = get(patientDoctorNote, 'value.symptoms', []);
    const data = {
      id: noteId,
      patient: patientId,
      history: get(patientDoctorNote, 'value.illnessHistory'),
      systems_review_general: get(patientDoctorNote, 'value.general'),
      record_number: get(patientDoctorNote, 'value.medicalRecord'),
      assessment: get(patientDoctorNote, 'value.assessment'),
      diseases: symptoms.map(({ value, text }) => ({ id: value, free_text: text })),
      draft: true,
      draft_page: get(patientDoctorNote, 'value.draftPage', '1'),
    };
    request({
      method: 'put',
      url: `/providers/${providerId}/doctor-notes/${noteId}`,
      data,
    }).catch((error) => console.info(error));
  }, [patientId, noteId, user, patientDoctorNote]);
}

export function useRemoveDoctorNoteDraft(noteId) {
  const fetchPatinetDoctorNotes = useFetchPatinetDoctorNotes();
  return React.useCallback(() => {
    request({
      method: 'delete',
      url: `/providers/doctor-notes/${noteId}`,
    })
      .then(() => {
        fetchPatinetDoctorNotes();
        createNotification({ message: 'Doctor note removed', type: 'success' });
      })
      .catch((error) => console.info(error));
  }, [noteId, fetchPatinetDoctorNotes]);
}

export function useFetchPatinetHealthPlans() {
  const { patientId } = useParams();
  const setPatientHealthPlans = useSetPatientHealthPlans((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/health-plan',
      params: {
        patient: patientId,
      },
    })
      .then(
        ({
          data: {
            data: { healthPlans },
          },
        }) => {
          setPatientHealthPlans(healthPlans);
        },
      )
      .catch((error) => console.info(error));
  }, [patientId, setPatientHealthPlans]);
}

export function useCreatePatinetHealthPlan() {
  const { patientId } = useParams();
  const history = useHistory();
  const { patientHealthPlan } = React.useContext(globalContext);
  return React.useCallback(
    (setIsSended) => {
      setIsSended(true);
      const formData = new FormData();
      formData.set('patient', patientId);
      formData.set('health_plan', get(patientHealthPlan, 'value.healthPlan', ''));
      const attachment = get(patientHealthPlan, 'value.file');
      if (attachment) {
        formData.set('attachment', attachment);
      }
      request({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        url: '/health-plan',
        data: formData,
      })
        .then(() => {
          setIsSended(false);
          createNotification({ message: 'Health plan created', type: 'success' });
          history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}`);
        })
        .catch((error) => {
          setIsSended(false);
          console.info(error);
        });
    },
    [patientHealthPlan, history, patientId],
  );
}

export function useFetchPatinetDocuments() {
  const { patientId } = useParams();
  const setPatientDocuments = useSetPatientDocuments((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/documents',
      params: {
        patient_id: patientId,
      },
    })
      .then(
        ({
          data: {
            data: { documents },
          },
        }) => {
          setPatientDocuments(documents);
        },
      )
      .catch((error) => console.info(error));
  }, [setPatientDocuments, patientId]);
}

export function useUploadPatinetDocument() {
  const { patientId } = useParams();
  const fetchPatinetDocuments = useFetchPatinetDocuments();
  return React.useCallback(
    ({ document, setIsSended }) => {
      const formData = new FormData();
      formData.set('patient_id', patientId);
      formData.set('document', document);
      setIsSended(true);
      request({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        url: '/documents',
        data: formData,
      })
        .then(() => {
          setIsSended(false);
          fetchPatinetDocuments();
          createNotification({ message: 'Document successfully uploaded', type: 'success' });
        })
        .catch((error) => {
          console.info(error);
          setIsSended(false);
        });
    },
    [patientId, fetchPatinetDocuments],
  );
}

export function useDownloadPatinetDocument(id) {
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/documents/${id}`,
    })
      .then(
        ({
          data: {
            data: { document },
          },
        }) => {
          const url = get(document, 'url');
          const link = window.document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          link.click();
        },
      )
      .catch((error) => console.info(error));
  }, [id]);
}

export function useFetchPatinetLabs() {
  const { patientId } = useParams();
  const setPatientLabs = useSetPatientLabs((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/labs',
      params: {
        patient_id: patientId,
      },
    })
      .then(
        ({
          data: {
            data: { labs },
          },
        }) => {
          setPatientLabs(labs);
        },
      )
      .catch((error) => console.info(error));
  }, [patientId, setPatientLabs]);
}

export function useUploadPatinetLab() {
  const { patientId } = useParams();
  const fetchPatinetLabs = useFetchPatinetLabs();
  return React.useCallback(
    ({ document, setIsSended }) => {
      const formData = new FormData();
      formData.set('patient_id', patientId);
      formData.set('file', document);
      setIsSended(true);
      request({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        url: '/labs',
        data: formData,
      })
        .then(() => {
          setIsSended(false);
          fetchPatinetLabs();
          createNotification({ message: 'Lab successfully uploaded', type: 'success' });
        })
        .catch((error) => {
          console.info(error);
          setIsSended(false);
        });
    },
    [patientId, fetchPatinetLabs],
  );
}

export function useDownloadPatinetLab(id) {
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/labs/${id}`,
    })
      .then(
        ({
          data: {
            data: { labs },
          },
        }) => {
          const url = get(labs, 'url');
          const link = window.document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          link.click();
        },
      )
      .catch((error) => console.info(error));
  }, [id]);
}

export function useFetchAdminNotes() {
  const { patientId } = useParams();
  const setPatientAdminNotes = useSetPatientAdminNotes((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/admins/notes',
      params: {
        patient: patientId,
      },
    })
      .then(
        ({
          data: {
            data: { adminNotes },
          },
        }) => {
          setPatientAdminNotes(adminNotes);
        },
      )
      .catch((error) => console.info(error));
  }, [patientId, setPatientAdminNotes]);
}

export function useCreatePatientAdminNote() {
  const history = useHistory();
  const { patientId } = useParams();
  const { patientAdminNote } = React.useContext(globalContext);
  return React.useCallback(() => {
    request({
      method: 'post',
      url: '/admins/notes',
      data: {
        patient: patientId,
        note: patientAdminNote.value.note,
      },
    })
      .then(() => {
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${ADMIN_NOTES}`);
      })
      .catch((error) => console.info(error));
  }, [patientId, patientAdminNote, history]);
}

export function useFetchPatientAdminNote() {
  const { noteId } = useParams();
  const setPatientAdminNote = useSetPatientAdminNote((prev, next) => next);
  const setPatientFullName = useSetPatientFullName((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/admins/notes/${noteId}`,
    })
      .then(
        ({
          data: {
            data: { adminNote },
          },
        }) => {
          setPatientAdminNote(adminNote);
          if (adminNote.patient.full_name) {
            setPatientFullName(adminNote.patient.full_name);
          }
        },
      )
      .catch((error) => console.info(error));
  }, [noteId, setPatientAdminNote, setPatientFullName]);
}

export function useRemoveAdminNote(noteId) {
  const fetchAdminNotes = useFetchAdminNotes();
  return React.useCallback(() => {
    request({
      method: 'delete',
      url: `/admins/notes/${noteId}`,
    })
      .then(() => {
        fetchAdminNotes();
        createNotification({ message: 'Administrative note removed', type: 'success' });
      })
      .catch((error) => console.info(error));
  }, [noteId, fetchAdminNotes]);
}

export function useFetchPatientInsurance() {
  const { patientId } = useParams();
  const setPatientInsurance = useSetPatientInsurance((prev, next) => next, []);
  const setLocalPatientInsurance = useSetLocalPatientInsurance((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/admins/patients/${patientId}/insurance`,
    })
      .then(({ data: { data } }) => {
        const normalizeData = { ...data };
        if (Array.isArray(data.insurances)) {
          normalizeData.insurances = {};
        }
        setPatientInsurance(normalizeData);
        setLocalPatientInsurance(normalizeData);
      })
      .catch((error) => console.info(error));
  }, [patientId, setPatientInsurance, setLocalPatientInsurance]);
}

export function useUpdatePatientInsurance() {
  const { patientId } = useParams();
  const { localPatientInsurance } = React.useContext(globalContext);
  const fetchPatientInsurance = useFetchPatientInsurance();
  return React.useCallback(() => {
    const data = cloneDeep(localPatientInsurance.value);
    data.insurances.primary = Object.fromEntries(
      Object.entries(data.insurances.primary ?? {}).filter(([key, value]) => {
        if (['insurance_card_front', 'insurance_card_back'].includes(key)) {
          if (!value || value?.startsWith('http')) {
            return false;
          }
        }
        return true;
      }),
    );
    if (data.no_insurance && data.insurances) {
      data.insurances = {};
    }
    request({
      method: 'post',
      url: `/admins/patients/${patientId}/insurance`,
      data,
    })
      .then(() => {
        fetchPatientInsurance();
        createNotification({ message: 'Insurance successfully changed', type: 'success' });
      })
      .catch((error) => console.info(error));
  }, [patientId, localPatientInsurance, fetchPatientInsurance]);
}

export function useFetchInsuranceCompanies() {
  const setInsuranceCompanies = useSetInsuranceCompanies((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/options/insurance-companies',
    })
      .then(({ data }) => setInsuranceCompanies(data))
      .catch((error) => console.info(error));
  }, [setInsuranceCompanies]);
}
