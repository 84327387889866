import React, { ReactElement } from 'react';

import { IconSvgProps } from 'components/new/Icon/Icon.types';

export const Document = ({
  height = 24,
  width = 24,
  className,
  strokeWidth = 1.5,
}: IconSvgProps): ReactElement => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M13.75 3.24251V6.96C13.75 7.46404 13.75 7.71607 13.8454 7.90859C13.9293 8.07793 14.0631 8.21562 14.2277 8.30191C14.4149 8.4 14.6599 8.4 15.15 8.4H18.7642M19 10.1894V16.68C19 18.1922 19 18.9482 18.7139 19.5258C18.4622 20.0338 18.0607 20.4469 17.5667 20.7057C17.0052 21 16.2702 21 14.8 21H9.2C7.72986 21 6.99479 21 6.43328 20.7057C5.93935 20.4469 5.53778 20.0338 5.28611 19.5258C5 18.9482 5 18.1922 5 16.68V7.32C5 5.80786 5 5.05178 5.28611 4.47423C5.53778 3.96619 5.93935 3.55314 6.43328 3.29428C6.99479 3 7.72986 3 9.2 3H12.0103C12.6523 3 12.9733 3 13.2755 3.0746C13.5433 3.14074 13.7993 3.24983 14.0343 3.39787C14.2991 3.56484 14.5261 3.79833 14.9802 4.2653L17.7698 7.1347C18.2239 7.60167 18.4508 7.83516 18.6132 8.10763C18.7571 8.3492 18.8632 8.61258 18.9275 8.88807C19 9.19881 19 9.52901 19 10.1894Z"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66699 12.5003C7.66699 12.0401 8.04009 11.667 8.50032 11.667H15.5003C15.9606 11.667 16.3336 12.0401 16.3336 12.5003C16.3336 12.9606 15.9606 13.3337 15.5003 13.3337H8.50032C8.04009 13.3337 7.66699 12.9606 7.66699 12.5003Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66699 16.5003C7.66699 16.0401 8.04009 15.667 8.50032 15.667H15.5003C15.9606 15.667 16.3336 16.0401 16.3336 16.5003C16.3336 16.9606 15.9606 17.3337 15.5003 17.3337H8.50032C8.04009 17.3337 7.66699 16.9606 7.66699 16.5003Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
