import React, { useState } from 'react';

import { ReactComponent as FileIcon } from 'assets/file.svg';
import Spinner from 'common/loaders/Spinner';
import { Modal } from 'components/new/Modal/Modal';

import styles from './ViewDoc.module.css';

type Props = {
  url: string;
  label: string;
  fileName: string;
};

export const ViewDoc = (props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const isPdfFile = /\.(pdf)$/i.test(props.fileName);

  if (isPdfFile) {
    return (
      <div className={styles.doc}>
        <FileIcon />
        <a href={props.url} target="_blank" rel="noopener noreferrer">
          {props.label}
        </a>
      </div>
    );
  }

  return (
    <div className={styles.doc}>
      <FileIcon />
      <button onClick={() => setOpenModal(true)}>{props.label}</button>
      {openModal && (
        <Modal
          minWidth={600}
          maxWidth={800}
          closeModal={() => setOpenModal(false)}
          withSeparator
          title={props.label}
        >
          <div className={styles.image}>
            {!isImageLoaded && <Spinner />}
            <img
              src={props.url}
              alt={props.label}
              width={350}
              onLoad={handleImageLoad}
              style={{ display: isImageLoaded ? 'block' : 'none' }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
