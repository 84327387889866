import React, { useMemo } from 'react';
import { SelectMethods, SelectProps, SelectState } from 'react-dropdown-select';

import { Icon } from 'components/new/Icon/Icon';

import styles from './Select.module.css';
import type { SearchSelectOption } from './Select.types';

export type DropdownRendererProps = {
  props: SelectProps<SearchSelectOption>;
  state: SelectState<SearchSelectOption>;
  methods: SelectMethods<SearchSelectOption>;
  isSearchable: boolean;
};

export const DropdownRenderer: React.FC<DropdownRendererProps> = ({
  props,
  state,
  methods,
  isSearchable,
}) => {
  const labelField = props.labelField as keyof (typeof props.options)[number];
  const valueField = props.valueField as keyof (typeof props.options)[number];

  const filteredOptions = useMemo(() => {
    if (!isSearchable) {
      return props.options;
    }
    const escapedSearch = state.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regexp = new RegExp(escapedSearch, 'i');

    return props.options.filter((item) =>
      regexp.test(item[props.searchBy as keyof typeof item] || item[labelField]),
    );
  }, [isSearchable, state.search, props.options, props.searchBy, labelField]);

  return (
    <div>
      {isSearchable && (
        <div className={styles.selectDropdownHeader}>
          <div className={styles.searchIcon}>
            <Icon name="search" height={18} width={18} />
          </div>
          <input
            className={styles.searchInput}
            type="text"
            value={state.search}
            onChange={methods.setSearch}
            placeholder="Search"
          />
        </div>
      )}
      <div className={styles.selectDropdownBody}>
        {filteredOptions.map((option) => {
          if (!props.keepSelectedInList && methods.isSelected(option)) {
            return null;
          }
          return (
            <div
              className={styles.selectDropdownItem}
              key={option[valueField]}
              onClick={() => methods.addItem(option)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  methods.addItem(option);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <p title={String(option[labelField])}>{option[labelField]}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
