import moment from 'moment';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import ConfirmationModal from 'common/ConfirmationModal';
import LinkButton from 'common/LinkButton';
import { UppercaseText, RegularText, PreFormatting } from 'common/texts';
import { FileItem } from 'components/new/FileItem/FileItem';
import { ThreeDotMenu } from 'components/new/ThreeDotMenu/ThreeDotMenu';
import { DETAILS, EDIT, HEALTH_PLANS, PATIENTS } from 'constants/routes';
import { API_URL_V2 } from 'env';
import { useModalHandler } from 'hooks/useUtils';
import { useFetchPatientHealthPlans } from 'requests/patients';
import createNotification from 'utils/createNotification';
import { getFileNameFromUrl } from 'utils/patients/utils';
import request from 'utils/request';

const PlanDetails = React.memo(function PlanDetails({ text, urls }) {
  return (
    <>
      <UppercaseText className="g-mt-10 g-mb-10">Health plan for patient</UppercaseText>
      <PreFormatting className="pseudo-textarea">{text}</PreFormatting>
      {urls?.map((url, id) => {
        const normalizedFileName = getFileNameFromUrl(url);
        return (
          <FileItem
            key={url}
            name={normalizedFileName || `Health Plan Attachment ${id}`}
            linkUrl={url}
            showRemoveIcon={false}
          />
        );
      })}
    </>
  );
});

export default React.memo(function PlanPreview({ id, medicalRecord, created, status, text, urls }) {
  const { patientId } = useParams();
  const history = useHistory();

  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useModalHandler();

  const [isNoteOpen, setIsNoteOpen] = React.useState(false);

  const { fetchPatientHealthPlans } = useFetchPatientHealthPlans();

  const deleteHealthPlan = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/health-plan/${id}`,
      baseURL: API_URL_V2,
    })
      .then(() => {
        fetchPatientHealthPlans();
        createNotification({ message: 'Document successfully deleted', type: 'success' });
      })
      .catch((error) => console.info(error))
      .finally(closeDeleteModal);
  }, [id, fetchPatientHealthPlans, closeDeleteModal]);

  const options = [
    {
      label: 'Edit',
      onClick: () => {
        history.push({
          pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}/${id}/${EDIT}`,
          state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}` },
        });
      },
    },
    {
      label: 'Delete',
      onClick: openDeleteModal,
      red: true,
    },
  ];

  return (
    <StyledPreview>
      <div className="status-wrapper">
        <div className="mrn g-mt-5 g-mb-5 g-mr-25">
          <UppercaseText>medical record number</UppercaseText>
          <RegularText>{medicalRecord}</RegularText>
        </div>

        <div className="g-mt-5 g-mb-5 g-d-flex g-justify-between g-align-center g-w-100">
          <div className="column g-mr-25">
            <UppercaseText>Date of creation</UppercaseText>
            <RegularText>{moment(created).format('MM.DD.YYYY')}</RegularText>
          </div>

          <div className="column g-mr-25">
            <UppercaseText>Status</UppercaseText>
            <RegularText>{status ? 'Viewed by patient' : 'Not viewed'}</RegularText>
          </div>

          <LinkButton className="view-button" onClick={() => setIsNoteOpen((prev) => !prev)}>
            {isNoteOpen ? 'Close' : 'View'}
          </LinkButton>
          <ThreeDotMenu options={options} />
        </div>
      </div>

      {isNoteOpen && <PlanDetails text={text} urls={urls} />}

      {isDeleteModalOpen && (
        <ConfirmationModal closeModal={closeDeleteModal} confirmHandler={deleteHealthPlan} />
      )}
    </StyledPreview>
  );
});

const StyledPreview = styled.div`
  padding: 2.4rem;
  margin-bottom: 1.5rem;
  background: #fafbfd;
  border-radius: 0.8rem;

  .mrn {
    width: 40%;
  }

  .column {
    width: 30%;
  }

  .view-button {
    padding: 0.6rem 3.5rem;
    min-width: 138px;
  }

  .status-wrapper {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: 480px) {
      justify-content: flex-start;
    }
  }

  .pseudo-textarea {
    background: #fff;
    padding: 1rem 1.5rem;
    border: solid 1px #e9ebef;
    font-size: 1.6rem;
  }
`;
