import React from 'react';

import Spinner from 'common/loaders/Spinner';
import { RegularMediumText } from 'common/texts';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import HealthPlans from 'components/patients/health-plan/HealthPlans';
import NewPlanButton from 'components/patients/health-plan/NewPlanButton';
import { HEALTH_PLANS } from 'constants/routes';
import { useFetchPatientHealthPlans } from 'requests/patients';

import styles from './PatientHealthPlans.module.css';

export default function PatientHealthPlans() {
  const { loading, fetchPatientHealthPlans } = useFetchPatientHealthPlans();

  React.useEffect(() => {
    fetchPatientHealthPlans();
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={HEALTH_PLANS}>
      <div className={styles.planContainer}>
        <NewPlanButton />
      </div>
      <RegularMediumText className="g-mb-10">Health plan</RegularMediumText>

      {loading ? <Spinner /> : <HealthPlans />}
    </DetailsTemplate>
  );
}
