import React, { ReactElement } from 'react';

import { IconSvgProps } from 'components/new/Icon/Icon.types';

export const Close = ({
  height = 24,
  width = 24,
  strokeWidth = 2,
  className,
}: IconSvgProps): ReactElement => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.84404 6.84332L17.1566 17.1559"
        stroke="currentColor"
        strokeOpacity="0.8"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M17.156 6.84332L6.8434 17.1559"
        stroke="currentColor"
        strokeOpacity="0.8"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};
