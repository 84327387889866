import React from 'react';

import { IconSvgProps } from 'components/new/Icon/Icon.types';

export const Upload: React.FC<IconSvgProps> = ({
  height = 24,
  width = 24,
  strokeWidth,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M7 12.9631L10 10.0002M10 10.0002L13 12.9631M10 10.0002V16.6668M16 13.5133C16.9161 12.7661 17.5 11.6356 17.5 10.3705C17.5 8.12048 15.6532 6.29646 13.375 6.29646C13.2111 6.29646 13.0578 6.21201 12.9746 6.07256C11.9966 4.43338 10.1908 3.3335 8.125 3.3335C5.0184 3.3335 2.5 5.8208 2.5 8.88905C2.5 10.4195 3.12659 11.8054 4.14021 12.8102"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        stroke="currentColor"
        strokeLinejoin="round"
      />
    </svg>
  );
};
