import classNames from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';

import { UppercaseText } from 'common/texts';

import styles from './index.module.css';
import { ErrorMessage } from '../ErrorMessage';

interface IInputProps {
  label?: string;
  containerClassName?: string;
  placeholder?: string;
  type?: string;
  readOnly?: boolean;
  value: string;
  changeHandler: (value: string) => void;
  isRequired?: boolean;
  isValid?: boolean;
  isLoading?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  disabled?: boolean;
  error?: string;
}

export const Input: React.FC<IInputProps> = ({
  label,
  containerClassName = '',
  placeholder = '',
  type = 'text',
  readOnly = false,
  value,
  changeHandler,
  isRequired = false,
  isValid = true,
  isLoading = false,
  inputRef,
  disabled = false,
  error,
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => changeHandler(e.target.value),
    [changeHandler],
  );

  if (isLoading) {
    return (
      <div className={classNames(styles.loader, containerClassName)}>
        {label && <div className={styles.loaderLabel} />}
        <div className={styles.loaderInput} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.wrapper, containerClassName)}>
      {label && (
        <label htmlFor={label} className={styles.label}>
          <UppercaseText>
            {label}
            {isRequired && <span className={styles.required}>*</span>}
          </UppercaseText>
        </label>
      )}
      <input
        id={label}
        ref={inputRef}
        type={type}
        className={classNames(styles.input, { [styles.inputInvalid]: !isValid || !!error })}
        readOnly={readOnly}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
      />
      {error && <ErrorMessage message={error} />}
    </div>
  );
};
