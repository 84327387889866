import React from 'react';

interface QualificationData {
  qualification_title?: string;
  qualifications: number[];
  state_licenses: string[];
}

export const useQualificationButtonDisabled = (
  isLoading: boolean,
  qualificationData: QualificationData | null,
) => {
  return React.useMemo(() => {
    if (isLoading) {
      return { isDisabled: true, message: 'Loading...' };
    }

    if (!qualificationData) {
      return { isDisabled: true, message: 'No qualification data available' };
    }

    const { qualification_title, qualifications, state_licenses } = qualificationData;

    if (!qualification_title?.trim()) {
      return { isDisabled: true, message: 'Please enter a qualification title' };
    }

    if (qualifications.length === 0) {
      return { isDisabled: true, message: 'Please select at least one qualification' };
    }

    const isHealthCoach = qualifications.length === 1 && qualifications.includes(1);
    if (isHealthCoach) {
      return { isDisabled: false, message: '' };
    }

    if (state_licenses.length === 0) {
      return { isDisabled: true, message: 'Please select at least one state license' };
    }

    return { isDisabled: false, message: '' };
  }, [isLoading, qualificationData]);
};
