import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MediumBoldText, UppercaseText } from 'common/texts';
import { Pagination } from 'components/new/Pagination/Pagination';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { DOCUMENTS } from 'constants/routes';
import request from 'utils/request';

import { DocumentProps, DocumentRow } from './DocumentRow';
import { DocumentUploader } from './DocumentUploader';
import styles from './index.module.css';
import { getExtension } from './utils';

export const PatientDocuments = () => {
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { patientId } = useParams<{ patientId: string }>();

  useEffect(() => {
    setLoading(true);
    fetchPatientDocuments(1);
  }, []);

  useEffect(() => {
    fetchPatientDocuments(page);
  }, [page]);

  const fetchPatientDocuments = (page: number) => {
    request({
      method: 'get',
      url: '/documents',
      params: {
        patient_id: patientId,
        per_page: 10,
        page,
      },
    })
      .then(({ data: { data } }) => {
        const { documents, pagination } = data;
        setTotalPages(pagination.last_page);
        setDocuments(
          documents.map((document) => ({
            name: document.name,
            type: document.document_type,
            uploadedBy: document.author?.full_name || 'N/A',
            uploadTime: document.uploaded,
            id: document.id,
            format: getExtension(document.name).toUpperCase(),
          })),
        );
      })
      .catch((error) => console.info(error))
      .finally(() => setLoading(false));
  };

  const renderExternalLogins = () => {
    const logins = [
      ['Evexia diagnostics', 'https://www.evexiadiagnostics.com/Account/Logon'],
      ['Vibrant-America', 'https://www.vibrant-america.com/secure/login.jsp'],
      ['Rupa Health', 'https://www.rupahealth.com'],
      ['Fullscript', 'https://fullscript.com'],
      ['Wells Pharmacy', 'https://www.wellsrx.com'],
    ];
    return logins.map(([name, url]) => (
      <p key={name}>
        <a className={styles.externalLogin} href={url} rel="noopener noreferrer" target="_blank">
          {name}
        </a>
      </p>
    ));
  };

  const headers = ['Document name', 'Document type', 'Format', 'Author', 'Uploaded'];

  const renderDocuments = () => {
    if (loading) {
      return [1, 2, 3].map((id) => (
        <DocumentRow
          id=""
          name=""
          type=""
          uploadedBy=""
          uploadTime={new Date()}
          format=""
          onDelete={() => null}
          key={`doc-${id}`}
        />
      ));
    }
    if (!documents.length) {
      return <p className={styles.description}>There are no labs uploaded yet</p>;
    }
    return (
      <div className={styles.documentContainer}>
        <div className={styles.header}>
          {headers.map((header, index) => (
            <UppercaseText key={index}>{header}</UppercaseText>
          ))}
          <UppercaseText key="delete" className={styles.delete}>
            Delete
          </UppercaseText>
        </div>
        {documents.map((document, index) => (
          <DocumentRow key={index} {...document} onDelete={() => fetchPatientDocuments(page)} />
        ))}
      </div>
    );
  };

  return (
    <DetailsTemplate currentTab={DOCUMENTS}>
      <div className={styles.section}>
        <MediumBoldText>External logins</MediumBoldText>
        {renderExternalLogins()}
      </div>
      <div className={styles.section}>
        <MediumBoldText>Upload</MediumBoldText>
        <DocumentUploader onUpload={() => fetchPatientDocuments(page)} />
      </div>
      <div className={styles.section}>
        <MediumBoldText>Uploaded Documents</MediumBoldText>
        {renderDocuments()}
      </div>
      <Pagination pageCount={totalPages} onPageChange={setPage} initialPage={1} />
    </DetailsTemplate>
  );
};
