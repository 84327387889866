export const ALLOWED_FORMATS = ['pdf', 'doc', 'docx'];

export const FILE_MAGIC_NUMBERS: Record<string, number[]> = {
  pdf: [0x25, 0x50, 0x44, 0x46],
  doc: [0xd0, 0xcf, 0x11, 0xe0],
  docx: [0x50, 0x4b, 0x03, 0x04],
  jpg: [0xff, 0xd8, 0xff],
  jpeg: [0xff, 0xd8, 0xff],
  png: [0x89, 0x50, 0x4e, 0x47],
};

const MAX_MAGIC_NUMBER_LENGTH = Math.max(
  ...Object.values(FILE_MAGIC_NUMBERS).map((arr) => arr.length),
);
const ONE_MB = 1024 * 1024;
const DEFAULT_MAX_FILE_SIZE_MB = 25;

export const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsArrayBuffer(file.slice(0, MAX_MAGIC_NUMBER_LENGTH));
  });
};

export const isValidMagicNumber = (buffer: Uint8Array, allowedTypes: string[]): boolean => {
  return allowedTypes.some((type) => {
    const signature = FILE_MAGIC_NUMBERS[type];
    return signature?.every((byte, i) => buffer[i] === byte);
  });
};

export const validateMagicNumber = async (file: File, allowedTypes: string[]): Promise<boolean> => {
  try {
    const arrayBuffer = await readFileAsArrayBuffer(file);
    const buffer = new Uint8Array(arrayBuffer);

    return isValidMagicNumber(buffer, allowedTypes);
  } catch (error) {
    console.error('Error validating magic number:', error);
    return false;
  }
};

export interface FileValidationResult {
  valid: boolean;
  error?: string;
}

export const validateFile = async (
  file: File,
  allowedFormats = ALLOWED_FORMATS,
  maxFileSizeMB = DEFAULT_MAX_FILE_SIZE_MB,
): Promise<FileValidationResult> => {
  const hasValidFormat = allowedFormats.some((format) => file.name.toLowerCase().endsWith(format));
  if (!hasValidFormat) {
    return {
      valid: false,
      error: `"${file.name}" has an invalid format. Formats Allowed: ${allowedFormats.join(', ')}`,
    };
  }

  const isWithinSize = file.size <= maxFileSizeMB * ONE_MB;
  if (!isWithinSize) {
    return {
      valid: false,
      error: `"${file.name}" exceeds the ${maxFileSizeMB}MB file size limit.`,
    };
  }

  const isMagicValid = await validateMagicNumber(file, allowedFormats);
  if (!isMagicValid) {
    return {
      valid: false,
      error: `"${file.name}" does not match its expected file type. Please upload a valid file.`,
    };
  }
  return { valid: true };
};
