import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';

import CustomCheckbox from 'common/CustomCheckbox';
import InputWithLabel from 'common/InputWithLabel';
import { FlexLine } from 'common/plate/styles';
import { RegularBigText } from 'common/texts';
import { useIsPatientProfileLoading } from 'hooks/patient/useProfile';
import { useLocalPatientProfile, useSetLocalPatientProfile } from 'hooks/usePatients';

function useSetProfilePrimaryCareProviderProperty(field) {
  return useSetLocalPatientProfile(
    (prev, next) => {
      const newPrev = cloneDeep(prev);
      set(newPrev, `primary_provider[${field}]`, next);
      return newPrev;
    },
    [field],
  );
}

export default React.memo(function PrimaryCareProviderInformation() {
  const isLoading = useIsPatientProfileLoading();
  const withoutProvider = useLocalPatientProfile((current) =>
    get(current, 'no_primary_provider', ''),
  );
  const name = useLocalPatientProfile((current) => get(current, 'primary_provider.name', ''));
  const address = useLocalPatientProfile((current) => get(current, 'primary_provider.address', ''));
  const officeNumber = useLocalPatientProfile((current) =>
    get(current, 'primary_provider.office_number', ''),
  );

  const toggleWithoutProvide = useSetLocalPatientProfile((prev) => {
    const withoutProvide = get(prev, 'no_primary_provider');
    set(prev, 'no_primary_provider', !withoutProvide);
    return prev;
  });

  return (
    <div>
      <RegularBigText className="g-mt-45">Primary care provider information</RegularBigText>
      <CustomCheckbox
        className="g-mr-25 g-mt-10"
        label="I don't have Primary care provider"
        checked={withoutProvider}
        changeHandler={toggleWithoutProvide}
      />
      <FlexLine>
        <InputWithLabel
          className="g-mr-25 g-mt-15"
          label="Provider’s name"
          disabled={withoutProvider}
          isRequred={!withoutProvider}
          isValid={name || withoutProvider}
          value={name}
          changeHandler={useSetProfilePrimaryCareProviderProperty('name')}
          isLoading={isLoading}
        />
        <InputWithLabel
          className="g-mr-25 g-mt-15"
          label="Address"
          disabled={withoutProvider}
          isRequred={!withoutProvider}
          isValid={address || withoutProvider}
          value={address}
          changeHandler={useSetProfilePrimaryCareProviderProperty('address')}
          isLoading={isLoading}
        />
        <InputWithLabel
          className="g-mr-25 g-mt-15"
          label="Office number"
          disabled={withoutProvider}
          isRequred={!withoutProvider}
          isValid={officeNumber || withoutProvider}
          value={officeNumber}
          changeHandler={useSetProfilePrimaryCareProviderProperty('office_number')}
          isLoading={isLoading}
        />
      </FlexLine>
    </div>
  );
});
