import React from 'react';

import { ReactComponent as Close } from 'assets/close.svg';
import { ReactComponent as FileIcon } from 'assets/file.svg';
import styles from 'components/new/FileItem/FileItem.module.css';

interface FileItemProps {
  name: string;
  linkUrl?: string;
  onRemove?: () => void;
  showRemoveIcon?: boolean;
}

export const FileItem: React.FC<FileItemProps> = ({
  name,
  linkUrl,
  onRemove,
  showRemoveIcon = false,
}) => {
  return (
    <div className={styles.fileContainer}>
      <div className={styles.fileContent}>
        <FileIcon />
        {linkUrl ? (
          <a href={linkUrl} target="_blank" rel="noreferrer" className={styles.fileLink}>
            {name}
          </a>
        ) : (
          <p className={styles.file}>{name}</p>
        )}
      </div>
      {showRemoveIcon && (
        <div>
          <Close className={styles.cross} onClick={onRemove} />
        </div>
      )}
    </div>
  );
};
