import React from 'react';

import styles from './InputLoader.module.css';

type InputLoaderProps = {
  labelHeight?: number;
  inputHeight?: number;
  hideLabel?: boolean;
};

export const InputLoader: React.FC<InputLoaderProps> = ({
  hideLabel,
  inputHeight,
  labelHeight,
}) => {
  return (
    <div className={styles.inputLoader}>
      {!hideLabel && <div className={styles.loaderLabel} style={{ height: labelHeight }} />}
      <div className={styles.loaderInput} style={{ height: inputHeight }} />
    </div>
  );
};
