import React, { FC, useState, useRef, useEffect, MouseEvent as ReactMouseEvent } from 'react';

import { ThreeDots } from 'assets/icons/ThreeDots';

import styles from './ThreeDotMenu.module.css';

export interface MenuOption {
  label: string;
  onClick: () => void;
  red?: boolean;
}

export interface ThreeDotMenuProps {
  options: MenuOption[];
}

export const ThreeDotMenu: FC<ThreeDotMenuProps> = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleMenuToggle = (event: ReactMouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (onClick: () => void) => {
    onClick();
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.menuWrapper} ref={menuRef}>
      <button
        type="button"
        onClick={handleMenuToggle}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label="Open menu"
        className={styles.menuButton}
      >
        <ThreeDots />
      </button>

      {isOpen && (
        <ul className={styles.menuList}>
          {options.map((option) => {
            const listItemClasses = [styles.menuItem, option.red ? styles.menuItemRed : '']
              .filter(Boolean)
              .join(' ');

            return (
              <li
                key={option.label}
                className={listItemClasses}
                onClick={() => handleOptionClick(option.onClick)}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
