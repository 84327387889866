import styled from 'styled-components';

const basePlateStyles = `
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0 1px 8px 0 rgba(20, 46, 110, 0.1);
`;

export const SquarePlate = styled.div`
  width: 100%;
  ${basePlateStyles};
  max-width: 42rem;
  min-height: 36rem;
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    width: 100%;
  }
`;

export const LargeSquarePlate = styled.div`
  width: 100%;
  ${basePlateStyles};
  max-width: 60rem;
  min-height: 60rem;
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    width: 100%;
  }
`;

export const ColumnPlate = styled.div`
  width: 100%;
  ${basePlateStyles};
  max-width: 42rem;
  min-height: 60rem;
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    width: 100%;
  }
`;

export const ColumnTightPlate = styled.div`
  width: 100%;
  ${basePlateStyles};
  max-width: 35rem;
  min-height: 60rem;
  @media only screen and (max-width: 480px) {
    max-width: initial;
    width: 100%;
  }
`;

export const ColumnWidePlate = styled.div`
  width: 100%;
  ${basePlateStyles};
  max-width: 53rem;
  min-height: 60rem;
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    width: 100%;
  }
`;

export const RowPlate = styled.div`
  width: 100%;
  ${basePlateStyles};
  min-height: 60rem;
  max-width: 100%;
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    width: 100%;
  }
`;

export const WideRowPlate = styled.div`
  width: 100%;
  ${basePlateStyles};
  min-height: 60rem;
  max-width: 100%;
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    width: 100%;
  }
`;
export const FullPlate = styled.div`
  ${basePlateStyles};
`;
