import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';

import { ReactComponent as UploadIcon } from 'assets/upload.svg';
import { UppercaseText } from 'common/texts';
import { FileItem } from 'components/new/FileItem/FileItem';
import styles from 'components/new/MultiFileUpload/MultiFileUpload.module.css';
import { ALLOWED_FORMATS, validateFile } from 'utils/fileValidation';

interface MultiFileUploadProps {
  onFilesSelect: (files: File[]) => void;
  allowedFormats?: string[];
  maxFileSizeMB?: number;
  label: string;
  className?: string;
}

export const MultiFileUpload: React.FC<MultiFileUploadProps> = ({
  onFilesSelect,
  allowedFormats = ALLOWED_FORMATS,
  maxFileSizeMB = 25,
  label,
  className,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [localFiles, setLocalFiles] = useState<File[]>([]);

  useEffect(() => {
    onFilesSelect(localFiles);
  }, [localFiles, onFilesSelect]);

  const triggerFileInput = (): void => {
    fileInputRef.current?.click();
  };

  const processNewFiles = async (incomingFiles: File[]) => {
    const newErrors: string[] = [];
    const validNewFiles: File[] = [];

    for (const file of incomingFiles) {
      const { valid, error } = await validateFile(file, allowedFormats, maxFileSizeMB);
      if (!valid && error) {
        newErrors.push(error);
      } else {
        validNewFiles.push(file);
      }
    }
    setErrors(newErrors);

    if (validNewFiles.length > 0) {
      setLocalFiles((prev) => [...prev, ...validNewFiles]);
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedFiles = Array.from(e.target.files || []);
    void processNewFiles(selectedFiles);
    e.target.value = '';
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>, isActive: boolean): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(isActive);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    handleDrag(e, false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    void processNewFiles(droppedFiles);
  };

  const removeFile = (index: number): void => {
    setLocalFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      {localFiles.length > 0 && (
        <div className={styles.filesList}>
          {localFiles.map((file, index) => {
            const name = file.name;
            return (
              <FileItem
                key={`${name}-${index}`}
                name={name}
                showRemoveIcon
                onRemove={() => removeFile(index)}
              />
            );
          })}
        </div>
      )}

      <UppercaseText className={styles.label}>{label}</UppercaseText>

      <div
        className={
          dragActive ? classNames(styles.dropZone, styles.dropZoneActive) : styles.dropZone
        }
        onClick={triggerFileInput}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            triggerFileInput();
          }
        }}
        onDragOver={(e) => handleDrag(e, true)}
        onDragEnter={(e) => handleDrag(e, true)}
        onDragLeave={(e) => handleDrag(e, false)}
        onDrop={handleDrop}
        tabIndex={0}
        role="button"
      >
        <div className={styles.dropZoneContent}>
          <UploadIcon className={styles.uploadIcon} />
          <p className={styles.uploadText}>
            Click to upload or drag and drop <br />
            one or multiple documents here
          </p>
          <p className={styles.formatsText}>
            Formats allowed: {allowedFormats.join(' ')}
            <br />
            Max size: {maxFileSizeMB}MB
          </p>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className={styles.hiddenInput}
          accept={allowedFormats.join(',')}
          multiple
          onChange={handleFileUpload}
        />
      </div>

      {errors.length > 0 && (
        <div className={styles.errorList}>
          {errors.map((err, index) => (
            <p className={styles.error} key={index}>
              {err}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
