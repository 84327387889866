import classNames from 'classnames';
import React from 'react';

import styles from './index.module.css';

interface IErrorMessageProps {
  message: string;
  className?: string;
}

export const ErrorMessage: React.FC<IErrorMessageProps> = ({ message, className = '' }) => {
  return <p className={classNames(styles.errorMessage, className)}>{message}</p>;
};
